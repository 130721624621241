.App {
  font-family: sans-serif;
  text-align: center;
}

#board-svg {
  margin-top: 30px;
  width: 600px;
  max-width: 100%;
}

.configuration-section {
  padding-top: 5px;
  padding-bottom: 5px;
}

.configuration-section label {
  margin-left: 15px;
}

.configuration-label section {
  margin-right: 15px;
}

.configuration-section button {
  margin: 5px;
}
